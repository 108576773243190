import { AppService } from './../../../services/app.service';
import { Component, OnInit, ElementRef, HostListener, Input, OnChanges } from '@angular/core';
import { ActivatedRoute, NavigationEnd, NavigationStart, Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { PermissionForUsers } from 'src/app/config/apiRouteConfig/checkroles';
import { COOKIE_URL, COOKIE_SUFFIX } from 'src/app/config/apiRouteConfig';
import Swal from 'sweetalert2';
import { RideService } from 'src/app/services/ride/ride.service';

import { StorageService } from '../../container/cache.service';
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit, OnChanges {
  collapse: boolean = true;
  notificationCollapse:boolean = true;
  isEnableMultiSelect: boolean = true;
  displayCreateTicket: boolean = false;
  notificationData=[];
  isLoggedIn:any=false;
  permissionsMap: { [key: string]: boolean } = {};
  notificationCount:any = '';
  socket:any;
  zonesList: any[] = [];
  selectedZones = [];
  ridePanelFlag = {};
  selectedZone: string;
  permissionMap = {}
  selectedDispatchNumber:string ='';
  displayDispatcherContact = false;
  dispatcherList = [];
  selectedCloseReason = {};
  closeRemarks:any = '';
  isSosAlert:any;
  isHomeChargeAlert:any;
  isCxLateAlert:any;
  notificationTime:any = '';
  closeReasons = [
    {
      label: 'Connected with customer on call',
      value: 'Connected with customer on call'
    },
    {
      label: 'Issue Resolved',
      value: 'Issue Resolved'
    },
    {
      label: 'Customer not reachable',
      value: 'Customer not reachable'
    }
];

  singleSelectList = ['/rides/liverides/beta','/livemap','/setting','/driver-on-duty','/journey-details','/reports','/vehicle', '/livemapbeta','/overspeeding','/rider-new','/overwriting_fare']

  toggleCollapse() {
    this.collapse = !this.collapse;
  }

  checkLogin(){
    let ssoId = localStorage.getItem('ssoId');
    if(ssoId){
      this.isLoggedIn = true;
      this.getNotificationsInfo();
    }
    else{
      this.isLoggedIn = false;
    }
  }

  constructor(public appService: AppService, private _router: Router, private _cookieService: CookieService,private el: ElementRef,private route: ActivatedRoute, private cacheService: StorageService,private _rideService: RideService) { 
    this._router.events.forEach((event) => {
     
      if(this.notificationTime){
        clearTimeout(this.notificationTime)
      }
      this.notificationTime = setTimeout(()=>{
        this.checkLogin();
      },1000)
      
      if(event instanceof NavigationStart) {
        this.startSocketService();
        this.initializePermissions();
        if(event.url === '/livemapbeta' || event.url === '/livemap' || event.url ===  '/setting' || event.url ==='/driver-on-duty' || event.url === '/overwriting_fare' || event.url === '/journey-details' || event.url==='/reports' || event.url ==='/vehicle' || event.url == '/overspeeding' || event.url == '/rider-new' || event.url == '/slots'
         || event.url === '/rides/liverides/beta' ){
          localStorage.setItem('lastVisitedUrl',event.url);
          this.isEnableMultiSelect = false;
          let serviceIds = this.appService.getCurrentServiceRegionId();
          this.appService.selectedZone = serviceIds[0];
          this.appService.setCurrentServiceRegionId(this.appService.selectedZone); 
        }else{
          localStorage.setItem('lastVisitedUrl',null);
          let zoneId = localStorage.getItem('zoneId');
          if(zoneId){
            this.appService.selectedZones = zoneId.split(",");
            this.appService.setCurrentServiceRegionId(zoneId.split(",")); 
          }
          
          this.isEnableMultiSelect = true;
        }  
        
      }
      // NavigationEnd
      // NavigationCancel
      // NavigationError
      // RoutesRecognized
    });
  }

  // checkPermission(permissionString: string) {
  //   console.log(permissionString)
  //   console.log( !["MENU_LIVE_RIDES", "MENU_LIVE_MAP_CLUSTER", "MENU_PAST_RIDES", "MENU_DRIVERS_ON_DUTY", 
  //   "MENU_SCHEDULE_RIDES", "MENU_DRIVER", "MENU_RIDER", "MENU_RIDER_NEW", "MENU_PAYMENTS", "MENU_SETTING"].includes(permissionString))
  //   if(localStorage.getItem('zoneId') == '4' && !["MENU_LIVE_RIDES", "MENU_LIVE_MAP_CLUSTER", "MENU_PAST_RIDES", "MENU_DRIVERS_ON_DUTY", 
  //   "MENU_SCHEDULE_RIDES", "MENU_DRIVER", "MENU_RIDER", "MENU_RIDER_NEW", "MENU_PAYMENTS", "MENU_SETTING"].includes(permissionString)){
  //      return false;
  //   }
  //   console.log(permissionString)
  // }
  // checkPermission(permissionString: String) {
  //   if (!this.permissionMap['' + permissionString])
  //     this.permissionMap['' + permissionString] = PermissionForUsers.checkPermission(permissionString);
  //   return this.permissionMap['' + permissionString]
  // }

  startSocketService(){
    // if (this.socket && this.socket.connected) {
    //   console.log('Socket connection already established.');
    //   return; // Prevent creating a new connection if already connected
    // }
    console.log('firingggg');
    
    this.notificationCount =0;
   // this.socket = this.socketService.setConnection();
    const zoneId = localStorage.getItem('zoneId') || '0'; 
    console.log(zoneId,'id23');
    
    // this.socket.on('connect', () => {
    //   console.log('WebSocket connecteddd');
    // });
  
    // this.socket.on('connect_error', (error) => {
    //   console.error('WebSocket connection error:', error);
    // });
  
    // this.socket.on('disconnect', (reason) => {
    //   console.warn('WebSocket disconnected:', reason);
    // });

// if(this.isHomeChargeAlert){

//   this.socket.on(`homeChargingAlertEvent_${zoneId}`,(data)=>{
//     console.log(data,'homeChargeSocket');
//     this.notificationData.push({...data.data,showResolve:false,isAlertClosed:false})
//     this.notificationCount = this.notificationData.length;
//     console.log(this.notificationCount,'count',this.notificationData);
    
//     })
// }
//  if(this.isSosAlert){
//   this.socket.on(`panicAlertEvent_${zoneId}`,(data)=>{
       
//     this.notificationData.push({...data.data,showResolve:false,isAlertClosed:false})
//     this.notificationCount = this.notificationData.length;
//     console.log(this.notificationCount,'count',this.notificationData);
    
//     })
//  }
    
//     if(this.isCxLateAlert){
//       this.socket.on(`cxRideRunningLateAlertEvent_${zoneId}`,(data)=>{
//         console.log(data,'cxRideRunningLateAlertEvent');
//         this.notificationData.push({...data.data,showResolve:false,isAlertClosed:false});
//         this.notificationCount = this.notificationData.length;
        
//         })
//     }
   
      

  }
  // checkPermission(permissionString: String) {
  //   return PermissionForUsers.checkPermission(permissionString);
  // }

  // checkPermission(permissionString: string) {
    
  //   console.log(permissionString,'perm')
  //   // console.log( !["MENU_LIVE_RIDES", "MENU_LIVE_MAP_CLUSTER", "MENU_PAST_RIDES", "MENU_DRIVERS_ON_DUTY",
  //   // "MENU_SCHEDULE_RIDES", "MENU_DRIVER", "MENU_RIDER", "MENU_RIDER_NEW", "MENU_PAYMENTS", "MENU_SETTING"].includes(permissionString))
  //   if(localStorage.getItem('zoneId') == '4' && !["MENU_LIVE_RIDES", "MENU_LIVE_MAP_CLUSTER", "MENU_PAST_RIDES", "MENU_DRIVERS_ON_DUTY",
  //   "MENU_SCHEDULE_RIDES", "MENU_DRIVER", "MENU_RIDER", "MENU_RIDER_NEW", "MENU_PAYMENTS", "MENU_SETTING"].includes(permissionString)){
  //      return false;
  //   }
  //   // console.log(permissionString)
  //   return true;
  // }

  checkPermission(permission: string): boolean {
    return this.permissionsMap[permission] ?? false;
  }
  initializePermissions(): void {
    const zoneId = localStorage.getItem('zoneId') || '0'; 
  
    
    const allPermissions = [
      'MENU_LIVE_RIDES',
      'MENU_LIVE_MAP_CLUSTER',
      'MENU_PAST_RIDES',
      'MENU_DRIVERS_ON_DUTY',
      'MENU_SCHEDULE_RIDES',
      'MENU_HOME_CHARGING',
      'MENU_BREAKDOWN_MANAGEMENT',
      'MENU_SLOTS_VISUAL',
      'MENU_VEHICLE',
      'MENU_DRIVER',
      'MENU_RIDER',
      'MENU_RIDER_NEW',
      'MENU_PAYMENTS',
      'MENU_REPORTS',
      'MENU_JOURNEY',
      'MENU_OVERSPEEDING',
      'MENU_OVERWRITING_FARE',
      'MENU_SETTING',
      'MENU_SLOTS',
    ];
  
    const restrictedPermissionsForZone4 = [
      'MENU_LIVE_RIDES',
      'MENU_LIVE_MAP_CLUSTER',
      'MENU_PAST_RIDES',
      'MENU_DRIVERS_ON_DUTY',
      'MENU_SCHEDULE_RIDES',
      'MENU_DRIVER',
      'MENU_RIDER',
      'MENU_RIDER_NEW',
      'MENU_PAYMENTS',
      'MENU_SETTING',
    ];
  
    
    this.permissionsMap = {};
  
    allPermissions.forEach((permission) => {
      if (zoneId === '4') {
      
        this.permissionsMap[permission] = restrictedPermissionsForZone4.includes(permission);
      } else {
       
        this.permissionsMap[permission] = true;
      }
    });
  
    console.log('Permissions initialized', this.permissionsMap); 
  }

  cancelResolve(idx){
    this.notificationData[idx].showResolve = false;

  }
  // ngOnDestroy():void{

  
  //     if(this.socket){
  //     this.socket.removeListener('homeChargingAlertEvent')
      
  //     this.socketService.disconnectSocket();
  //   }
    
  // }
  // ngOnDestroy(): void {
  //   if (this.socket) {
  //     this.socket.removeAllListeners(); // Remove all event listeners
  //     this.socket.disconnect(); // Properly disconnect the socket
  //     this.socket = null; // Reset the socket reference
  //   }
  // }

 async handleNotificationCollapse(){

    if(this.notificationCollapse){
      this.notificationData =[];

      
     await this.getNotificationsData();
      // this.updateNotifications('READ',true);
      this.notificationCollapse = !this.notificationCollapse;
  
    }
    else if(!this.notificationCollapse){
   
      if(this.isLoggedIn){
        this.updateNotifications('CLOSED',true)
        this.getNotificationsCount();
        this.getNotificationsData();
      }
     
      this.notificationCollapse = !this.notificationCollapse;
      // setTimeout(()=>{
      //   this.notificationCollapse = !this.notificationCollapse;
      // },1200)
    }
  
  }
  // && !event.target.parentNode.classList.includes('ui-dropdown-label-container')
  @HostListener('document:click', ['$event'])
  async clickout(event) {

      if(this.el.nativeElement.contains(event.target)) {
      } else {
        this.collapse = true;
        
        if(!this.notificationCollapse){
          this.getNotificationsCount()
        }
        if(event.target.className != 'check-ride-btn' && event.target.className != 'resolve-btn' && (event.target.parentNode.classList[0]!= 'ui-dropdown-item' && event.target.classList[0] != 'ui-dropdown-item') ){
          this.notificationCollapse = true;
        //  await( this.notificationCount =0);
        //   console.log(this.notificationCount,'counteee');
          
        }
      

      }
  }

  toggleResolve(idx){
    
    if(this.notificationData[idx].showResolve){

    }
    else{
      this.notificationData[idx].showResolve = true;
    }
  }

  openSlotsVisual(){
   
    this._router.navigate(['rides/slotsVisual'])
    
  }
  checkRide(rideId?:any){
   

    this._router.navigate([], {
      relativeTo: this.route, // Use the current route as the base
      queryParams: { rideId: rideId ,dispatchNo:null}, // Add or update query parameters
      queryParamsHandling: 'merge', // Merge with existing query parameters
    });
  }
  

  callDriver(dispatchNo:any){
   
    console.log(dispatchNo,'noo');
    this.selectedDispatchNumber = localStorage.getItem('dispatcherNum');
    this._router.navigate([], {
      relativeTo: this.route,
      queryParams: { dispatchNo: null,rideId:null }, // Reset the query parameter first
      queryParamsHandling: 'merge', // Merge with existing query parameters
    }).then(() => {
      this._router.navigate([], {
        relativeTo: this.route,
        queryParams: { dispatchNo: dispatchNo,rideId:null }, // Add the actual query parameter
        queryParamsHandling: 'merge',
      });
    });
  }
  

  removeCard(cardId: number,idx?:any) {
    const cardElement = document.querySelector(`.notification-card[data-id="${cardId}"]`);
    if (cardElement) {
      // this.notificationData[idx]['isAlertClosed'] = true;
      setTimeout(()=>{
        cardElement.classList.add('swipe-out'); // Add the swipe animation class

        // Remove the card after the animation completes
        cardElement.addEventListener('animationend', () => {
          this.notificationData = this.notificationData.filter(card => card.id !== cardId);
        });
      },0)
   
    }
  }
  onReasonChange(e){
  
    this.selectedCloseReason = {
      label: e.value,
      value: e.value
    }


}
  updateNotifications(action?:any,isAll?:boolean,id?:any,idx?:any){
    
    let notificationIds = isAll?this.notificationData.map((notf)=>{
      return(notf.id)
    }):[id];

    let userId = localStorage.getItem("ssoId");
    let body = {
      userId:userId,
      notificationIds:notificationIds,
      updateStatus:action,
      resolvedReason: action == 'RESOLVED'?this.selectedCloseReason['value']:'',
      resolvedDescription:action == 'RESOLVED'?this.closeRemarks :''

    }

    this.appService.updateNotifications(body).subscribe((res:any) => {

    if(!isAll && (action=='CLOSED' || action == 'RESOLVED') && res.statusCode == 200){
      this.removeCard(id,idx);
    }
    else if(isAll && action == 'CLOSED'){
      for(let i=0;i<notificationIds.length;i++){
        this.removeCard(notificationIds[i].id)
      }
    }

    if(action == 'RESOLVED'){
      this.selectedCloseReason = {};
      this.closeRemarks = ''
    }
    
    })
  }

  // closeNotifications(id?:any){
  //   let notificationsIds = id?[id]:this.notificationData.map((not)=>{
  //     return(not.id)
  //   })
  //   let userId = localStorage.getItem("ssoId");
  //   let body = {
  //     userId:userId,
  //     notificationIds:notificationsIds,
  //     updateStatus:'CLOSED'

  //   }
  //   this.appService.updateNotifications(body).subscribe((res:any) => {
  
  //   console.log(res);
    
  //   })
  
  // }


  callToDriverDialog(event) {

    this._rideService.getDispatcherList().subscribe((res => {
      this.dispatcherList = res.response;
      this.ridePanelFlag = {};
      this.displayDispatcherContact = true;
      if (!this.selectedDispatchNumber && this.dispatcherList && this.dispatcherList.length > 0)
        this.selectedDispatchNumber = this.dispatcherList[0]
      if(event)
        event.stopPropagation();
    }))

  }
  

  callToDriver=(event, data,_rideService) =>{
    if(event)
      event.stopPropagation();
    this.selectedDispatchNumber = localStorage.getItem('dispatcherNum');

    if (!this.selectedDispatchNumber) {
      this.callToDriverDialog(event)
    } else {
      if(_rideService)
      _rideService.callConnectToDriver(this.selectedDispatchNumber, data,true).subscribe((res => {
        this.displayDispatcherContact = false;
        Swal.fire({
          title: "Connecting!",
          text: "Your call is connecting to Driver ",
          confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
          timer: 3000
        }).then(result => {
          event.stopPropagation();
          this.displayDispatcherContact = false;
        })
      })) 
      else 
      this._rideService.callConnectToDriver(this.selectedDispatchNumber, data, true).subscribe((res => {
        this.displayDispatcherContact = false;
        Swal.fire({
          title: "Connecting!",
          text: "Your call is connecting to Driver ",
          confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
          timer: 3000
        }).then(result => {
          event.stopPropagation();
          this.displayDispatcherContact = false;
        })
      }))

    }
  }
  getAllZones(){
    this.appService.getAllZones('119').subscribe((res:any) => {
      Object.entries(res.response).forEach((ele) => { 
        let zone = {
          "label": ele[1],
          "value": ele[0]
        }
         this.zonesList.push(zone);
      });
      localStorage.setItem("zonesList",JSON.stringify(this.zonesList))
      this.selectedZones.push(this.zonesList[0].value);
      this.selectedZone = this.zonesList[0].value;
    
    })
  }
  convertToReadableTime(timestamp: number): string {
    const currentTime = Date.now(); // Current time in milliseconds
    const timeDifference = currentTime - timestamp; // Difference in milliseconds
  
    const minutes = Math.floor(timeDifference / (1000 * 60));
    const hours = Math.floor(timeDifference / (1000 * 60 * 60));
    const days = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
  
    if (days > 0) {
      return `${days} day${days > 1 ? 's' : ''}`; // Convert to days if greater than a day
    } else if (hours > 0) {
      return `${hours} hour${hours > 1 ? 's' : ''}`; // Convert to hours if greater than an hour
    } else {
      return `${minutes} minute${minutes > 1 ? 's' : ''}`; // Default to minutes
    }
  }
  
  getNotificationsData(){
 
    if(this.notificationData.length == 0 && (this.isSosAlert || this.isHomeChargeAlert))
    {
      let userId = localStorage.getItem('ssoId');

        this.appService.getNotificationsData(userId,this.isSosAlert,this.isHomeChargeAlert,this.isCxLateAlert).subscribe((res:any) => {
    
          let notifications = []
          for(let i=0;i<res.response.length;i++){
            notifications.push({...res.response[i],showResolve:false,isAlertClosed:false})
          }
          this.notificationData = notifications;
          console.log( this.notificationData,'4');
      
          this.updateNotifications('READ',true);
        })
  }
  }
checkAlertsPermission(){
     let userRoles = JSON.parse(localStorage.getItem("userRoles"));
     this.isSosAlert= userRoles.includes("SOS_ALERT"); 
    // this.isSosAlert= userRoles.includes("ALERT_SOS");
     this.isHomeChargeAlert = userRoles.includes("HOME_CHARGING_ALERTS");
    // this.isHomeChargeAlert = userRoles.includes("ALERT_HOME_CHARGING");
     this.isCxLateAlert = userRoles.includes("SOS_ALERT");
  
     
}

 async ngOnInit() {
  await(this.checkAlertsPermission());

    let zoneId = localStorage.getItem('zoneId');
    // let ssoId = localStorage.getItem('ssoId');
    // if(ssoId){
    //   this.isLoggedIn = true;
    // }
    this.appService.setCurrentServiceRegionId(zoneId);
    if(zoneId== 'null' || !zoneId){
      this.logout();
      return
    }
    if(zoneId && zoneId.length == 1 && localStorage.getItem('lastVisitedUrl') != 'null' && localStorage.getItem('lastVisitedUrl') != null){
      this.isEnableMultiSelect = false;
      this.appService.selectedZone = zoneId;
    }else{
      this.isEnableMultiSelect = true;
      this.appService.selectedZones = zoneId.split(",");
    } 
    // this.startSocketService();
    this.initializePermissions();
    this.appService.getAllZones(localStorage.getItem('ssoId')).subscribe((res:any) => {
            if(res.status == 'SUCCESS'){
                  this.appService.zonesList =[]
                  Object.entries(res.response).forEach((ele) => {
                    let zone = {
                        "label": ele[1],
                        "value": ele[0]
                    }
                    this.appService.zonesList.push(zone);
                    localStorage.setItem("zonesList",JSON.stringify(this.appService.zonesList));
                  })
                }
              })

  }

  getNotificationsInfo(){
    this.getNotificationsCount();
    // this.getNotificationsData();
  }


getNotificationsCount(){
  let userId = localStorage.getItem('ssoId');

    this.appService.getNotificationCount(userId).subscribe((res:any) => {
    this.notificationCount = res.response
  })

  
}

  closeNav(url) {
 
    if(url === '/login' )
       return
    this.collapse = true;
    localStorage.setItem('lastVisitedUrl',url);
     let zoneId = localStorage.getItem('zoneId');
   
    if(url === '/livemap' || url === '/livemapbeta' || url ===  '/setting' || url ==='/driver-on-duty' || url === '/journey-details' || url==='/reports' || url ==='/vehicle' || url === '/overwriting_fare' || url =='/overspeeding' || url == '/rider-new' || url == 'slots' || url == 'sos-management' || url == 'home-charging' ){
      this.isEnableMultiSelect = false;
      let serviceIds = this.appService.getCurrentServiceRegionId();
      this.appService.selectedZone = serviceIds[0];
      this.appService.setCurrentServiceRegionId(this.appService.selectedZone); 
    }else{
      if(zoneId && zoneId.length > 1){
        this.appService.selectedZones = zoneId.split(",");
        this.appService.setCurrentServiceRegionId(zoneId.split(",")); 
      }
       
      this.isEnableMultiSelect = true;
    }    
  }

  openNav() {
    this.collapse = !this.collapse;
  }

  logout() {
    this.appService.logout().subscribe(
      (response) => {
        this._cookieService.delete('accessToken' + COOKIE_SUFFIX, '/', COOKIE_URL);
        this._cookieService.set("accessToken"+COOKIE_SUFFIX, null, 1, "/",COOKIE_URL,true,"Lax");
        this.cacheService.clearPickupLocation();
        this.appService.isValid = false;
        localStorage.clear();
        this.appService.name = null
        this._router.navigateByUrl('/login');
      },
      (err) => {
        //console.log(err);
      }
    )
  }

  ngOnChanges(){
   
  }

  onRideTypeChange(value) {
    if (value == 'all')
      value = null
    this.appService.setRideType(value);
    this._router.navigate(["rides"])
    this._router.navigateByUrl('/login', { skipLocationChange: true }).then(() => {
      setTimeout(() => { this._router.navigate(["/rides"]) }, 10);
    });
  }

  onServiceRegionChange(value) {
    if(value.length == 0){
      this.appService.selectedZones = [this.appService.zonesList[0].value];
      value = [this.appService.zonesList[0].value]
    }
    
    this.appService.setCurrentServiceRegionId(value);
    this.appService.selectedZones = value

    this._router.routeReuseStrategy.shouldReuseRoute = () => false;
    this._router.onSameUrlNavigation = 'reload';
    this._router.navigate([this._router.url]);

    // if (location.pathname.indexOf('liverides')>=0) {
    //   this._router.navigateByUrl('/rides/schedulerides', { skipLocationChange: true }).then(() => {
    //     this._router.navigate([location.pathname]);
    //   });
    // } else {
    //   this._router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
    //     this._router.navigate([location.pathname]);
    //   });
    // }

  }
  openCreateTicketDailog(){
    this.displayCreateTicket = true
  }

}
